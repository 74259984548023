import * as React from "react"
import { Link } from "gatsby"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"
const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/retail/",
    label: "RETAIL",
  },
]

const resourcesList = [
  {
    title: "VigilantMEDR",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMNDR",
    subtitle: " Managed Network Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="RETAIL" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-2">
      <section className="section-title-top--platform">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>RETAIL</h1>
              <h4>BRAND AND CUSTOMER PROTECTION</h4>
              <p className="content">
                Our Tailored Solutions Targets Any Security Gap
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    get started
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-copy-img-r about-us--hero-img-retail">
      <div className="container">
        <div className="row">
          <div className="section-col-1 desk-only">
            <div className="side-binder about-us--gives">
              <h3>
                BRAND AND <br />
                BUSINESS <br /> CONTINUITY
              </h3>
              <h6>
                STOP CRIMINALS. <br />
                PROTECT CUSTOMERS.
              </h6>
            </div>
          </div>
          <div className="section-col-2">
            <div className="side-binder about-us--gives mob-only">
              <h1>OUR MISSION</h1>
              <h3>TO BE VIGILANT</h3>
            </div>
            <p>
              With so many endpoints and decentralized business models, retail
              companies are particularly vulnerable to e-crime – leading to
              revenue loss and brand damage.
            </p>
            <p>
              Our massive security footprint (network, endpoint, email coverage
              and our 24/7 CyberDNA® Command and Control Center) offer non-stop
              protection of your global infrastructure. Vigilant is unique in
              providing an Unlimited Breach Response promise. That means, that
              we will show up early, remediate every potential breach and do so
              with no additional cost, no matter how many attacks are attempted.
            </p>
            <p>
              Cybersecurity never seems urgent – until it is. Most days you are
              being pulled in a thousand directions, so you need a partner who
              will ensure that the “urgent moment” never comes.
            </p>

            <p>
              From our 24/7/365 US-based CyberDNA® Command and Control Center,
              we watch your network, endpoints, email flow, customer
              integration, decentralized network, global threat trends and
              retail sector specific tactics. We inspect all of your cyberdata
              and constantly monitor for malicious activity.
            </p>
            <p>
              Bonus: we have the highest true-positive analysis process, so your
              busy staff will only be interrupted with actionable intelligence.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div className="grid--bg --for-it-ops-1--center">
      <section className="section-client-quote">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h3>
                  “Vigilant saved us hundreds of thousands by stopping an
                  attacker had bypassed our security protocols and halted out
                  supply chain.”
                </h3>
                <hr />
                <h6>- CLIENT IN RETAIL INDUSTRY</h6>
                <p className="pBlue">
                  (For security reasons, Vigilant never reveals the identity of
                  our clients)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
